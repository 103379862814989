import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const contactData = {
	phone: ["+1 403 680 8008"],
	email: ["mail@ryanmanning.ca"],
	location: "Lethbridge, AB",
};

function Contact() {
	const [formdata, setFormdata] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
	});

	const [error, setError] = useState(false);
	const [message, setMessage] = useState("");
	const submitBtnRef = useRef();

	const submitHandler = (event) => {
		event.preventDefault();
		setError(false);
		setMessage("");
		// validate
		if (!formdata.name) {
			setError(true);
			setMessage("Name is required");
		} else if (!formdata.email) {
			setError(true);
			setMessage("Email is required");
		} else if (!formdata.subject) {
			setError(true);
			setMessage("Subject is required");
		} else if (!formdata.message) {
			setError(true);
			setMessage("Message is required");
		} else {
			// modify submit btn
			submitBtnRef.current.innerText = "Sending message, please wait..";
			submitBtnRef.current.classList.remove("btn-default");
			submitBtnRef.current.classList.add("btn-warning", "disabled");
			submitBtnRef.current.disabled = true;

			//send email using @emailjs/browser
			emailjs.send("ryanmanning.ca", "template_zqfcd3o", formdata, "SadMLVkfRXJTa-3LI").then(
				(response) => {
					console.log("SUCCESS!", response.status, response.text);
					// reset submit btn
					submitBtnRef.current.innerHTML = '<i class="icon-paper-plane"></i>Send Message';
					submitBtnRef.current.classList.remove("btn-warning", "disabled");
					submitBtnRef.current.classList.add("btn-default");
					submitBtnRef.current.disabled = false;
					
					// reset form
					//formdata.name = "";
					//formdata.email = "";
					//formdata.subject = "";
					//formdata.message = "";

					// proper reset form method
					setFormdata((prevState) => {
						return {
							name: "",
							email: "",
							subject: "",
							message: "",
						};
					});
					// show the user a success message
					setError(false);
					setMessage("You message has been sent!");
				},
				(err) => {
					console.log("FAILED...", err);
					// reset submit btn
					submitBtnRef.current.innerHTML = '<i class="icon-paper-plane"></i>Send Message';
					submitBtnRef.current.classList.remove("btn-warning", "disabled");
					submitBtnRef.current.classList.add("btn-default");
					submitBtnRef.current.disabled = false;
					// show the user an error
					setError(true);
					setMessage("Email failed to send due to network issue/failure.");
				}
			);
		}
	};

	const handleChange = (event) => {
		setFormdata({
			...formdata,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleAlerts = () => {
		if (error && message) {
			return <div className="alert alert-danger mt-4">{message}</div>;
		} else if (!error && message) {
			return <div className="alert alert-success mt-4">{message}</div>;
		} else {
			return null;
		}
	};

	return (
		<div className="row">
			<div className="col-md-4 mb-4 mb-md-0">
				<div className="contact-info mb-5">
					<i className="icon-phone"></i>
					<div className="details">
						<h5>Phone</h5>
						{contactData.phone.map((singlePhone, index) => (
							<span key={index}>{singlePhone}</span>
						))}
					</div>
				</div>
				<div className="contact-info mb-5">
					<i className="icon-envelope"></i>
					<div className="details">
						<h5>Email address</h5>
						{contactData.email.map((singleEmail, index) => (
							<span key={index}>{singleEmail}</span>
						))}
					</div>
				</div>
				<div className="contact-info">
					<i className="icon-location-pin"></i>
					<div className="details">
						<h5>Location</h5>
						<span>{contactData.location}</span>
					</div>
				</div>
			</div>

			<div className="col-md-8">
				<form className="contact-form" onSubmit={submitHandler}>
					<div className="row">
						<div className="column col-md-6">
							<div className="form-group">
								<input type="text" className="form-control" name="name" placeholder="Your name" onChange={handleChange} value={formdata.name} />
							</div>
						</div>

						<div className="column col-md-6">
							<div className="form-group">
								<input type="email" className="form-control" name="email" placeholder="Email address" onChange={handleChange} value={formdata.email} />
							</div>
						</div>

						<div className="column col-md-12">
							<div className="form-group">
								<input type="text" className="form-control" name="subject" placeholder="Subject" onChange={handleChange} value={formdata.subject} />
							</div>
						</div>

						<div className="column col-md-12">
							<div className="form-group">
								<textarea name="message" className="form-control" rows="5" placeholder="Message" onChange={handleChange} value={formdata.message}></textarea>
							</div>
						</div>
					</div>

					<button type="submit" name="submit" value="Submit" className="btn btn-default" ref={submitBtnRef}>
						<i className="icon-paper-plane"></i>Send Message
					</button>
				</form>
				{handleAlerts()}
			</div>
		</div>
	);
}

export default Contact;
