import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaImdb,
  FaYoutube,
  FaEnvelope
} from "react-icons/fa";

const headerData = {
  name: "Ryan Manning",
  designation: "Web Developer  ·  Audio Engineer",
  imageThumb: "/images/logo.png",
  social: {
    linkedin: "http://ca.linkedin.com/pub/ryan-manning/40/b41/959",
    //facebook: "https://facebook.com/ryanvmanning",
    twitter: "https://twitter.com/yanvanman",
    //instagram: "https://twitter.com/yanvanman",
    imdb: "https://www.imdb.com/name/nm1625066/",
    //youtube: "https://www.youtube.com/",
    email: "mailto:ryan@manni.ng"
  },
};

function Header({ toggleHeader, toggleHandler }) {
  return (
    <>
      <div
        className={
          toggleHeader
            ? "mobile-header py-2 px-3 mt-4 push"
            : "mobile-header py-2 px-3 mt-4"
        }
      >
        <button className="menu-icon mr-2" onClick={toggleHandler}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Link to="/multipage" className="logo">
          <img src={headerData.imageThumb} alt={headerData.name} />
        </Link>
        <Link to="/" className="site-title dot ml-2">
          {headerData.name}
        </Link>
      </div>

      <header
        className={
          toggleHeader
            ? "left float-left shadow-dark open"
            : "left float-left shadow-dark"
        }
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleHandler}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="header-inner d-flex align-items-start flex-column">
          <Link to="/multipage">
            <img src={headerData.imageThumb} alt={headerData.name} />
          </Link>
          <Link to="/multipage" className="site-title dot mt-3">
            {headerData.name}
          </Link>

          <span className="site-slogan">{headerData.designation}</span>

          <nav>
            <ul className="vertical-menu scrollspy">
              <li>
                <Link to="/multipage">
                  <i className="icon-home"></i>Home
                </Link>
              </li>
              <li>
                <Link to="/about">
                  <i className="icon-user"></i>About
                </Link>
              </li>
              {/* <li>
                <Link to="/services">
                  <i className="icon-bulb"></i>Services
                </Link>
              </li> */}
              <li>
                <Link to="/resume">
                  <i className="icon-graduation"></i>Experience
                </Link>
              </li>
              <li>
                <Link to="/portfolio">
                  <i className="icon-grid"></i>Portfolio
                </Link>
              </li>
              {/* <li>
                <Link to="/bloglist">
                  <i className="icon-pencil"></i>Blog
                </Link>
              </li> */}
              <li>
                <Link to="/contact">
                  <i className="icon-phone"></i>Contact
                </Link>
              </li>
            </ul>
          </nav>

          <div className="footer mt-auto">
            <ul className="social-icons list-inline">
              {!headerData.social.linkedin ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.linkedin} title="LinkedIn" target="_blank" rel="noreferrer">
                    <FaLinkedinIn />
                  </a>
                </li>
              )}
              {!headerData.social.facebook ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.facebook} title="Facebook" target="_blank" rel="noreferrer">
                    <FaFacebookF />
                  </a>
                </li>
              )}              
              {!headerData.social.twitter ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.twitter} title="Twitter" target="_blank" rel="noreferrer">
                    <FaTwitter />
                  </a>
                </li>
              )}
              {!headerData.social.instagram ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.instagram} title="Instagram" target="_blank" rel="noreferrer">
                    <FaInstagram />
                  </a>
                </li>
              )}
              {!headerData.social.imdb ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.imdb} title="IMDB" target="_blank" rel="noreferrer">
                    <FaImdb />
                  </a>
                </li>
              )}
              {!headerData.social.youtube ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.youtube} title="YouTube" target="_blank" rel="noreferrer">
                    <FaYoutube />
                  </a>
                </li>
              )}
              {!headerData.social.email ? null : (
                <li className="list-inline-item">
                  <a href={headerData.social.email} title="Email">
                    <FaEnvelope />
                  </a>
                </li>
              )}
            </ul>

            <span className="copyright">
              &copy; {new Date().getFullYear()} All Rights Reserved.
            </span>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
