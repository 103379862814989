import React from "react";
import { Link as ScrollLink } from "react-scroll";
import convertHtmlToReact from "@hedgedoc/html-to-react";

const aboutData = {
	cvpath: "media/Ryan Manning%20-%20Resume.pdf",
	image: "images/about.png",
	name: "Ryan Manning",
	location: "Lethbridge, AB",
	birthday: "April 8th, 1980",
	email: "ryan@manni.ng",
	aboutMe:
		"I am Ryan Manning, a web developer from Lethbridge, Alberta." +
		"<br /><br />I have extensive experience in website design, scripting, programming, and full-stack development. I am proficient in a variety of programming languages, including PHP, JavaScript, Java, Python, Dart, and Bash/Shell. Additionally, I am well-versed in numerous frameworks and technologies, such as React.js, Node.js, jQuery, Bootstrap, WordPress/WooCommerce, PHP Composer, Flutter, Git, and Subversion.</i>." +
		'<br /><br />I am also highly trained in audio engineering and production, and have a number of professional credits listed on <a href="https://www.imdb.com/name/nm1625066/" target="_blank" rel="noreferrer">The International Movie Database (IMDb)</a>.',
};

function About() {
	return (
		<div className="row">
			<div className="col-md-3">
				<img src={aboutData.image} alt={aboutData.name} />
			</div>
			<div className="col-md-9">
				<h2 className="mt-4 mt-md-0 mb-4">Hello,</h2>
				<p className="mb-4">{convertHtmlToReact(aboutData.aboutMe)}</p>
				<div className="row my-4 d-none">
					<div className="col-md-6">
						<p className="mb-2">
							Name:{" "}
							<span className="text-dark">{aboutData.name}</span>
						</p>
						<p className="mb-0">
							Birthday:{" "}
							<span className="text-dark">
								{aboutData.birthday}
							</span>
						</p>
					</div>
					<div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
						<p className="mb-2">
							Location:{" "}
							<span className="text-dark">
								{aboutData.location}
							</span>
						</p>
						<p className="mb-0">
							Email:{" "}
							<span className="text-dark">{aboutData.email}</span>
						</p>
					</div>
				</div>
				<a
					href={aboutData.cvpath}
					target="_blank"
					rel="noreferrer"
					className="btn btn-default mr-3 mb-2 mb-sm-0"
				>
					<i className="icon-cloud-download"></i>Download CV
				</a>
				<ScrollLink
					activeClass="active"
					to="contact"
					spy={true}
					smooth={true}
					duration={500}
					offset={50}
					className="btn btn-alt mb-2 mb-sm-0"
				>
					<i className="icon-envelope"></i>Contact me
				</ScrollLink>
			</div>
		</div>
	);
}

export default About;
