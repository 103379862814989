import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
	{
		id: 1,
		year: "October 2020",
		degree: "Leadership Skills for Managers",
		content:
			'The <a href="https://www.chinooklearningservices.com/ContEd/AdultCourses/Leadership-Skills-For-Managers.html" target="_blank" rel="noreferrer">Leadership Skills for Managers</a> course focuses on developing effective leadership qualities, analyzing team dynamics to enhance performance, and communicating effectively in various situations.' +
			"<br /><br />The course also covers creating strategic work plans and making decisions aimed at achieving results. Additionally, it helps managers understand and apply delegation techniques to optimize team members’ strengths, handle conflict and anger assertively, and foster a motivating and productive work environment." +
			"<br /><br />Ryan completed this course through Chinook Learning Services in October 2020, following his promotion to Lead Software Engineer at The Respect Group.",
	},
	{
		id: 2,
		year: "March 2023",
		degree: "Respect in the Workplace Training",
		content:
			'<a href="https://www.respectgroupinc.com/respect-in-the-workplace/" target="_blank" rel="noreferrer">Respect in the Workplace</a> is a 90-minute online interactive training program designed to provide organizations with a cost-effective tool to empower your team with the skills to prevent bullying, abuse, harassment, and discrimination (BAHD).' +
			'<br /><br />Ryan continually earned a <a href="./media/repsect-in-the-workplace-cert.pdf" target="_blank" rel="noreferrer">certificate of completion</a> for the Respect in the Workplace training program during his tenure at Respect Group Inc.',
	},
	{
		id: 2,
		year: "1999 - 2001",
		degree: "The Recording Arts Masters Program",
		content:
			'The <a href="./media/CDIS-RAMP.pdf" target="_blank" rel="noreferrer">Recording Arts Masters Program</a> at the Center for Digital Imaging & Sound (CDIS) in Vancouver, British Columbia offered comprehensive, hands-on training in audio engineering and production theory.' +
			"<br /><br />It provided a robust foundation in essential equipment, techniques, and protocols for entry-level roles in audio/visual production and post-production." +
			"<br /><br />Additionally, the program included training in IT and equipment troubleshooting, as well as an introductory course in web design and development." +
			"<br /><br />Ryan completed the 3-year program and received his diploma in July of 2001." +
			'<br /><br />The Center for Digital Imaging & Sound has since re-branded as <a href="https://www.artinstitutes.edu/" target="_blank" rel="noreferrer">The Art Institute of Vancouver.',
	},
	{
		id: 3,
		year: "1995 - 1998",
		degree: "High School Diploma",
		content:
			'Ryan attended  <a href="https://lordbeaverbrook.cbe.ab.ca" target="_blank" rel="noreferrer">Lord Beaverbrook High School</a> in Calgary, Alberta and received his diploma upon graduation in July of 1998.',
	},
];

function Experiences() {
	return (
		<div className="timeline">
			{experiencesData.map((experience) => (
				<Experience experience={experience} key={experience.id} />
			))}
			<span className="timeline-line"></span>
		</div>
	);
}

export default Experiences;
