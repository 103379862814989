import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
	{
		id: 1,
		year: "2012 - PRESENT",
		degree: "Director of Technology",
		org: "Respect Group, Inc.",
		content:
			'The <a href="http://www.respectgroupinc.com/" target="_blank" rel="noreferrer">Respect Group</a>, founded by Sheldon Kennedy and Wayne McNeil, is dedicated to preventing abuse, bullying, harassment, and neglect in the workplace, sports, and education. Their mission is to empower individuals to recognize and address these issues through interactive online training courses.' +
			"<br /><br />In his role as Director of Technology, Ryan is responsible for the development and management of the company’s online platforms and e-learning systems. He leverages the latest advancements in web development technologies and leads a team of skilled professionals across various technological roles." +
			"<br /><br />Ryan utilizes a range of technologies and programming languages in his role, including JavaScript, HTML5, CSS3, PHP, Java, and MySQL. He is also experienced with Linux server architecture and networking stacks. His work incorporates various web-based frameworks and libraries such as React.js, jQuery, Bootstrap, Node.js, WordPress, Composer, Google Flutter, and Apache Cordova." +
			"<br /><br />His duties extend beyond web and mobile development to include audio/video production, IT systems administration, technical support, and consultation. Ryan is proficient in Adobe Creative Suite, using Photoshop, Audition, Animate, and Captivate to enhance design and multimedia projects. He also handles API integrations with third-party platforms to expand functionality and improve system interoperability." +
			"<br /><br />Additionally, Ryan contributes to the day-to-day operations of the company, providing technical and IT helpdesk support to employees and a broad user base. As Senior Front End Developer, he manages the software development team, ensuring effective task delegation and adherence to deadlines. He is also responsible for code quality assurance and managing deployments using version control systems like Subversion and Git.",
	},
	// {
	//   id: 2,
	//   year: "2016 - 2018",
	//   degree: "Chief Technology Officer / Senior Developer",
	//   org: "Simply Delivery",
	//   content:
	//     ""
	// },
	{
		id: 3,
		year: "2016 - 2018",
		degree: "Chief Technology Officer / Partner",
		org: "RPM² Holdings LLC",
		content:
			"Ryan served as the Chief Technology Officer and founding partner of <b>RPM² Holdings LLC</b>, a company he co-founded with three other partners in Calgary, Alberta." +
			"<br /><br />The corporation played a key role in launching <b>Vapor Jackpot</b>, an online marketplace specializing in a diverse range of e-liquids for use with atomizers and vaporizers." +
			"<br /><br />Ryan was responsible for the end-to-end web design, development, and deployment of this venture, handling everything from conception to launch. He utilized a wide array of web technologies and frameworks, including WordPress, WooCommerce, MySQL, PHP, jQuery, Bootstrap, Node.js, Flutter, and Adobe Creative Suite, among others." +
			"<br /><br />In addition to his technical responsibilities, Ryan was deeply involved in the company's daily operations. He managed various tasks such as marketing, advertising, promotion, social media campaigns, graphic design, product inventory, order fulfillment, invoicing, and customer relations.",
	},
	{
		id: 4,
		year: "2010 - 2016",
		degree: "IT Systems Manager",
		org: "Aarvak Services, Inc.",
		content:
			'Ryan was the IT Systems Manager for <a href="http://www.aarvak.com/" target="_blank" rel="noreferrer">Aarvak Services, Inc.</a>, an electrical engineering firm with offices located in both Houston, Texas and Calgary, Alberta.' +
			"<br /><br />Ryan had worked as a contractor with Aarvak Services since 2010, managing the company's extensive networks and computer systems. His responsibilities included overseeing hardware upgrades, software deployments, and ongoing computer maintenance. Ryan also provided IT consultation, support, and troubleshooting as needed." +
			"<br /><br />Notable achievements include the complete design, development, and deployment of an outdoor security surveillance system at a gas processing plant in West Virginia. This system featured seven night-vision PTZ cameras and a DVR recording unit, all remotely accessible and controllable via the internet." +
			"<br /><br />Additional accomplishments involve designing, developing, and maintaining the company’s website; deploying their phone system and toll-free number using advanced VOIP technologies; and integrating an internet-based file storage and accounting solution with Amazon S3 and virtual private networking.",
	},
	{
		id: 5,
		year: "2007 - 2012",
		degree: "Audio Engineer / Systems Administrator",
		org: "Essentialtalk",
		content:
			"As an Audio Producer and Recording Engineer, Ryan was responsible for producing all audio content for Essentialtalk and its subsidiary companies. Much of this production was intended for web-based delivery within Adobe Flash presentations and animations. His work included recording voice-overs, editing dialogue, adding music and sound effects, and mixing and mastering audio content, all completed within strict deadlines." +
			"<br /><br />Ryan was also heavily involved in video production, overseeing tasks from filming and editing to duplication, streaming, and conversion. This role required travel across Canada and the United States to record and live-stream events for clients." +
			"<br /><br />In his role as Systems Administrator, Ryan managed the day-to-day operations and maintenance of the company’s technical infrastructure. His responsibilities included installing and upgrading computer systems and hardware, performing repairs and maintenance, and deploying new software and technologies." +
			"<br /><br />Additionally, Ryan was deeply engaged in researching new technologies and integrating them into the workplace. He supported the Network Administrator with duties such as server deployment, migration, maintenance, backup, domain registration, website hosting, SSL certificate management, and network configuration including Wireless/LAN/VPN. He also provided technical support for studio equipment and computer systems, as well as general help desk support." +
			'<br /><br />Essentialtalk has since re-branded as <a href="https://icehealthsystems.com" target="_blank" rel="noreferrer">ICE Health Systems</a>.',
	},
	{
		id: 6,
		year: "2006 - 2007",
		degree: "Systems & Design Install Administrator",
		org: "DMX Music Canada",
		content:
			'At <a href="https://en.wikipedia.org/wiki/DMX_(music_service)" target="_blank" rel="noreferrer">DMX Music Canada</a>, Ryan was responsible for the implementation, design, and deployment of professional audio systems for a diverse range of clients, including those in the hotel and hospitality industry, private businesses, and corporations. His role involved ensuring the highest level of sound fidelity and coverage while adhering to budget constraints.' +
			"<br /><br />Ryan’s duties also included researching new products and modern design applications. Additionally, he utilized the company’s specialized Linux-based software to create designs and proposals, generate work orders, and reference previous purchase orders." +
			'<br /><br />Ryan received Certification from <a href="https://pro.bose.com/en_us/resources/learning-center.html" target="_blank" rel="noreferrer">Bose Professional</a> in September of 2006 while employed with DMX Music Canada.',
	},
	{
		id: 7,
		year: "2005 - 2008",
		degree: "Audio Visual Technician",
		org: "Inland Audio Visual",
		content:
			'As an Audio Visual Technician for <a href="https://inlandav.ca/" target="_blank" rel="noreferrer">Inland Audio Visual</a>, Ryan was responsible for setting up and tearing down audio and visual equipment at hotels, businesses, trade shows, and special events. His tasks included the installation of microphones, speakers, mixing boards, cameras, projection screens, projectors, language translation booths, lighting, controllers, staging, and draping. He also operated this equipment during live events.' +
			"<br /><br />Additional duties involved producing and editing audio and video content for clients, mass replication of media formats such as CDs, DVDs, and VHS tapes, and managing inventory for the company’s extensive equipment and supplies." +
			"<br /><br />Ryan’s technical expertise was also valuable for troubleshooting and resolving issues with Inland's audio-visual systems and computer related equipment.",
	},
	{
		id: 8,
		year: "2001 - 2005",
		degree: "Audio Engineer  / Systems Administrator",
		org: "Bluewater Studios",
		content:
			'As the second head engineer at <a href="http://www.bluewaterstudios.ca" target="_blank" rel="noreferrer">Bluewater Studios</a>, Ryan\'s responsibilities consisted of recording and editing ADR voice tracks with Avid / Digidesign Pro Tools software on an Apple Macintosh platform for use in animations, cartoons, video games, auditions, voice demos & workshops – All while maintaining a professional recording environment with the ability to meet deadlines and work under stressful conditions in a fast-paced work environment' +
			"<br /><br />Ryan also provided IT systems administration and help desk support to staff, helping to maintain computers. networks, studio equipment, and audio/video gear. His duties included performing hardware and software upgrades and installations" +
			'<br /><br />Ryan earned several professional film credits while working for Bluewater Studios, some of which are listed under his name at <a href="https://www.imdb.com/name/nm1625066/" target="_blank" rel="noreferrer">The International Movie Database (IMDb)</a>.',
	},
];

function Experiences() {
	return (
		<div className="timeline">
			{experiencesData.map((experience) => (
				<Experience experience={experience} key={experience.id} />
			))}
			<span className="timeline-line"></span>
		</div>
	);
}

export default Experiences;
