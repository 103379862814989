import React, { useState } from "react";
import Header from "./Header";

function Layout({ children }) {
  const [toggleHeader, setToggleHeader] = useState(false);
  const handleToggle = () => {
    setToggleHeader(!toggleHeader);
    console.log(!toggleHeader)
  };
  
  // added by ryan to remove 'push' when mobile-header menu displayed but window goes full screen
  // note I referenced this: https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
  // along with: https://www.kindacode.com/article/react-how-to-programmatically-add-remove-classes/ (but ended up using method to target all elments with these classes specifically using native js)
  function handleResize() {    
    if (window.innerWidth > 990) {
      //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
      setToggleHeader(false);
      // remove open and push classes if window size is greater than 990px
      const pushedElements = document.querySelectorAll('div.mobile-header.push, main.content.push');
      if (pushedElements.length > 0) {
        pushedElements.forEach((element) => {
          element.classList.remove('push');
        });
      }
      const openElements = document.querySelectorAll('header.open');
      if (openElements.length > 0) {            
        openElements.forEach((element) => {
          element.classList.remove('open');
        });
      }
    }
  }
  try {
    window.removeEventListener('resize', handleResize);
  } catch(e) {
    // do nothing
  }
  window.addEventListener('resize', handleResize);
  
  return (
    <div className="site-wrapper">
      <Header toggleHeader={toggleHeader} toggleHandler={handleToggle} />
      <main 
        className={
          toggleHeader ? "content float-right push" : "content float-right"
        }
      >
        {children}
      </main>
    </div>
  );
}


export default Layout;
