import React, { useState, useEffect } from "react";
import Portfolio2 from "../Items/Portfolio2";

const filters = [
	{
		id: 1,
		name: "All Projects",
	},
	{
		id: 2,
		name: "Web",
	},
	{
		id: 3,
		name: "Audio",
	},
	{
		id: 5,
		name: "eLearning",
	},
	{
		id: 4,
		name: "eCommerce",
	},
];

const allData = [
	{
		id: 1,
		name: "Koala Platform",
		category: ["web", "saas", "elearning", "app"],
		image: "images/portfolio/koala.png",
		slug: "koala-platform",
	},
	{
		id: 2,
		name: "Respect Group Inc Website",
		category: ["web", "wordpress"],
		image: "images/portfolio/rgi.png",
		slug: "respect-group-inc",
	},
	{
		id: 3,
		name: "The Sheldon Kennedy Show",
		category: ["web", "wordpress", "podcast", "audio"],
		image: "images/portfolio/thesheldonkennedyshow.png",
		slug: "the-sheldon-kennedy-show",
	},
	{
		id: 4,
		name: "Canadian DNA Services",
		category: ["web", "wordpress", "ecommerce"],
		image: "images/portfolio/canadian-dna-services.png",
		slug: "canadian-dna-services",
	},
	{
		id: 5,
		name: "Stay In The Game",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/stay-in-the-game.png",
		slug: "stay-in-the-game",
	},
	{
		id: 6,
		name: "Vapor Jackpot",
		category: ["web", "wordpress", "ecommerce", "app"],
		image: "images/portfolio/vapor-jackpot.png",
		slug: "vapor-jackpot",
	},
	{
		id: 7,
		name: "Tank Plumbing Ltd.",
		category: ["web", "wordpress"],
		image: "images/portfolio/tankplumbing.png",
		slug: "tank-plumbing",
	},
	{
		id: 8,
		name: "Dragonball",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/dragonball.png",
		slug: "dragonball",
	},
	{
		id: 9,
		name: "Dragonball GT",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/dragonball-gt.png",
		slug: "dragonball-gt",
	},
	{
		id: 10,
		name: "Mega Man X: Command Mission",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-x.png",
		slug: "mega-man-x",
	},
	{
		id: 11,
		name: "Mega Man Powered Up",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-powered-up.png",
		slug: "mega-man-powered-up",
	},
	{
		id: 12,
		name: "Mega Man X8",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/mega-man-x8.png",
		slug: "mega-man-x8",
	},
	{
		id: 13,
		name: "Mobile Fighter G Gundam",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/g-gundam.png",
		slug: "g-gundam",
	},
	{
		id: 14,
		name: "Gregory Horrow Show",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/gregory-horror-show.png",
		slug: "gregory-horror-show",
	},
	{
		id: 15,
		name: "Crimson Tears",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/crimson-tears.png",
		slug: "crimson-tears",
	},
	{
		id: 16,
		name: "DNA Integrated Cybernetic Enterprises",
		category: ["audio", "recording", "editing", "game"],
		image: "images/portfolio/dice.png",
		slug: "dice",
	},
	{
		id: 17,
		name: "Betterman",
		category: ["audio", "recording", "editing"],
		image: "images/portfolio/betterman.png",
		slug: "betterman",
	},
	{
		id: 18,
		name: "Healthy Youth Relationships",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/healthy-youth-relationships.png",
		slug: "healthy-youth-relationships",
	},
	{
		id: 19,
		name: "Lacing Up",
		category: ["web", "saas", "elearning"],
		image: "images/portfolio/lacing-up.png",
		slug: "lacing-up",
	},
	{
		id: 20,
		name: "Simply Delivery",
		category: ["web", "wordpress", "ecommerce", "app"],
		image: "images/portfolio/simply-delivery.png",
		slug: "simply-delivery",
	},
	{
		id: 21,
		name: "Erika Navarro",
		category: ["web", "wordpress"],
		image: "images/portfolio/erika-navarro.png",
		slug: "erika-navarro",
	},
	{
		id: 22,
		name: "Aarvak Services",
		category: ["web", "wordpress"],
		image: "images/portfolio/aarvak-services.png",
		slug: "aarvak-services",
	},
	{
		id: 23,
		name: "Johnston Power & Energy",
		category: ["web", "wordpress"],
		image: "images/portfolio/johnston-power.png",
		slug: "aarvak-services",
	},
	{
		id: 24,
		name: "Bulldog Moviing",
		category: ["web", "wordpress"],
		image: "images/portfolio/bulldog-moving.png",
		slug: "bulldog-moving",
	},
	{
		id: 25,
		name: "Contour Earthmoving Ltd",
		category: ["web", "custom", "flash"],
		image: "images/portfolio/contour.png",
		slug: "contour",
	},
	{
		id: 26,
		name: "Rednex Bar & Grill",
		category: ["web", "custom"],
		image: "images/portfolio/rednex.png",
		slug: "rednex",
	},
	{
		id: 27,
		name: "Linkdump",
		category: ["web", "custom"],
		image: "images/portfolio/linkdump.png",
		slug: "linkdump",
	},
	{
		id: 28,
		name: "RSSDump",
		category: ["web", "custom"],
		image: "images/portfolio/rssdump.png",
		slug: "rssdump",
	},
	{
		id: 29,
		name: "AltaLink ERP Training",
		category: ["web", "saas", "elearning", "captivate", "scorm"],
		image: "images/portfolio/altalink.png",
		slug: "altalink",
	},
	{
		id: 30,
		name: "Rampira",
		category: ["web", "saas", "elearning", "captivate", "scorm"],
		image: "images/portfolio/rampira.png",
		slug: "rampira",
	},
	{
		id: 31,
		name: "The Big Rock Commercial",
		category: ["audio", "recording", "editing", "video"],
		image: "images/portfolio/bigrock.png",
		slug: "bigrock",
	},
];

function Portfolios2() {
	const [getAllItems] = useState(allData);
	const [dataVisibleCount, setDataVisibleCount] = useState(6);
	const [dataIncrement] = useState(3);
	const [activeFilter, setActiveFilter] = useState("");
	const [visibleItems, setVisibleItems] = useState([]);
	const [noMorePost, setNoMorePost] = useState(false);

	useEffect(() => {
		setActiveFilter(filters[0].name.toLowerCase());
		setVisibleItems(getAllItems.filter((item) => item.id <= 6));
	}, [getAllItems]);

	const handleChange = (e) => {
		console.log(e.target.value);
		e.preventDefault();
		let targetFilter = e.target.value
			? e.target.value.toLowerCase()
			: e.target.textContent.toLowerCase();
		setActiveFilter(targetFilter);
		let tempData;
		if (targetFilter === filters[0].name.toLowerCase()) {
			tempData = getAllItems.filter(
				(data) => data.id <= dataVisibleCount
			);
			setNoMorePost(false); // added when commenting out line below to display all items when changing category
		} else {
			tempData = getAllItems.filter((data) => {
				return data.category.includes(
					targetFilter
				) /* && data.id <= dataVisibleCount*/;
			});
			setNoMorePost(true); // added when commenting out line above to display all items when changing category
		}
		setVisibleItems(tempData);
	};

	const handleLoadmore = (e) => {
		e.preventDefault();
		let tempCount = dataVisibleCount + dataIncrement;

		if (tempCount > getAllItems.length) {
			setNoMorePost(true);
		} else {
			setDataVisibleCount(tempCount);
			if (activeFilter === filters[0].name.toLowerCase()) {
				setVisibleItems(
					getAllItems.filter((data) => data.id <= tempCount)
				);
				tempCount = dataVisibleCount + dataIncrement;
				if (tempCount >= getAllItems.length) {
					setNoMorePost(true);
				}
			} else {
				let items = getAllItems.filter((data) => {
					return (
						data.category.includes(activeFilter) &&
						data.id <= tempCount
					);
				});
				setVisibleItems(items);
				tempCount = dataVisibleCount + dataIncrement;
				if (tempCount >= getAllItems.length) {
					setNoMorePost(true);
				}
			}
		}
	};

	return (
		<>
			<ul className="portfolio-filter list-inline">
				{filters.map((filter) => (
					<li
						className={
							filter.name.toLowerCase() === activeFilter
								? "list-inline-item current"
								: "list-inline-item"
						}
						key={filter.id}
						onClick={handleChange}
					>
						{filter.name}
					</li>
				))}
			</ul>

			<div className="pf-filter-wrapper mb-4">
				<select
					className="portfolio-filter-mobile"
					onChange={(e) => handleChange(e)}
				>
					{filters.map((filter) => (
						<option value={filter.name} key={filter.id}>
							{filter.name}
						</option>
					))}
				</select>
			</div>

			<div className="row portfolio-wrapper">
				{visibleItems.map((item) => (
					<div className="col-md-4 col-sm-6 grid-item" key={item.id}>
						<Portfolio2 portfolio={item} />
					</div>
				))}
			</div>

			{noMorePost ? null : (
				<div className="load-more text-center mt-4">
					<a
						href="#!"
						className="btn btn-default"
						onClick={(e) => handleLoadmore(e)}
					>
						<i className="fas fa-circle-notch"></i> Load more
					</a>
				</div>
			)}
		</>
	);
}

export default Portfolios2;
