import React from "react";
import convertHtmlToReact from '@hedgedoc/html-to-react';


function Experience({ experience: { year, degree, org, content } }) {
  return (
    <div className="entry">
      <div className="title">
        <span>{year}</span>
      </div>
      <div className="body">
        <h4 className="mt-0">
          {degree}
          {!org ? null : (
            <small className="ml-2">| {org}</small>
          )}
        </h4>
        
        <p>{convertHtmlToReact(content)}</p>
      </div>
    </div>
  );
}

export default Experience;
